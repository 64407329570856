import React, { useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"
import Layout from "../components/Layout"
import SEO from "../components/SEO/seo"
import { siteData } from "../data/siteData"
import { Link, useStaticQuery, graphql } from "gatsby"
import SpotlightBanner from "../components/SpotlightBanner"
import FreeTrialLesson from "../components/FreeTrialLesson"
import Img from "gatsby-image"

const ClassesPage = () => {
  useEffect(() => {
    const myId = window.location.hash.slice(1)
    const elem = document.getElementById(myId)
    if (elem) {
      elem.scrollIntoView()
    }
  }, [])

  const data = useStaticQuery(graphql`
    query SpotlightBannerClassesImageQuery {
      spotlightBannerImage: file(
        relativePath: { eq: "classes-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      classesElementaryImage: file(relativePath: { eq: "classes-elementary.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      classesKindyImage: file(relativePath: { eq: "classes-kinder.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      classesJhsHsImage: file(relativePath: { eq: "classes-jhs-hs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      classesAdultImage: file(relativePath: { eq: "classes-adult.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <SEO metaData={siteData.classesPage} siteData={siteData}/>
        {/* <!-- Banner section --> */}

        <SpotlightBanner
          bannerImage={data.spotlightBannerImage.childImageSharp.fluid}
          title="クラス"
        />

        {/* <!-- Banner section --> */}
        <ScrollAnimation
          //offset={80}
          animateIn="fadeInUp"
          duration={1}
          animateOnce={true}
        >
          <section className="classlink-section">
            <ul className="classlink-list font-20">
              <li className="classlink-item bg-yellow">
                <Link to="/classes#childrens-class" className="link-white">
                 園児クラス
                </Link>
              </li>
              <li className="classlink-item bg-green">
                <Link to="/classes#elementry-class" className="link-white">
                  小学生クラス
                </Link>
              </li>
              <li className="classlink-item bg-blue">
                <Link to="/classes#junior-class" className="link-white">
                  中高生クラス
                </Link>
              </li>
              <li className="classlink-item bg-red">
                <Link to="/classes#adult-class" className="link-white">
                  大人クラス
                </Link>
              </li>

              {/*
              <li className="classlink-item bg-primary">
                <Link to="/classes#fees" className="link-white">
                  授業料
                </Link>
              </li>
              */}

            </ul>
          </section>
        </ScrollAnimation>

        {/* <!-- Welcome text section --> */}
        <section className="welcome-section inner-padding">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 text-center">
                <div className="static-text">
                  <p>
                    アメリカンイングリッシュハウスでは園児クラス（年少〜年長）・小学生クラス・中高生クラス・大人クラスの４つのクラスを用意しています。英語の学習経験やレベルに合わせてご自分に合うクラスを選択することもできます。どのクラスも年度途中の入会が可能です。振替レッスンもご利用いただけます。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Classes --> */}
        <section className="classes-section">
          <div className="classes-section-holder">
            {/* <!-- Childrens class --> */}

            <div className="class-wrap yellow" id="childrens-class">
              <div className="class-wrap-inner wrap">
                <div className="container">
                  <div className="row">
                    <div className="classes-flex-handler">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="classes-flex-wrapper">
                          <div className="staff-cols img-cols">
                            <div className="vector-title-wrapper show-from-ipad">
                              <h2 className="font-30 class-title">
                                Kinder Class
                              </h2>
                              <h3 className="font-30 class-subtitle">
                                園児クラス (年少〜年長)
                              </h3>
                              <div className="vector vector-children" />
                            </div>

                            <div className="img-wrap">
                              <Img
                                fluid={data['classesKindyImage'].childImageSharp.fluid}
                                className="class-img" alt=" 園児クラス"
                              />
                            </div>

                            <ul className="img-desc-wrap">
                              <li className="img-desc-item">
                                <i className="icon icon-x3 icon-clock" />
                                <span className="desc font-20">50分</span>
                              </li>
                              <li className="img-desc-item flex-grow-1">
                                <i className="icon icon-x3 icon-teach" />
                                <span className="desc font-20">
                                  振替レッスンあり！
                                </span>
                              </li>
                              <li className="img-desc-item">
                                <i className="icon icon-x3 icon-yen" />
                                <span className="desc font-20">¥7,700 / 月謝</span>
                              </li>
                            </ul>
                          </div>

                          <div className="staff-cols content-cols">
                            <div className="vector-title-wrapper hide-from-ipad">
                              <h2 className="font-30 class-title">
                                Kinder Class
                              </h2>
                              <h3 className="font-30 class-subtitle">
                                園児クラス (年少〜年長)
                              </h3>
                              <div className="vector vector-children" />
                            </div>
                            <div className="content-wrapper">
                              <p>
                                園児クラスは年少クラスと年中＆年長クラスの２つに分かれています。歌や楽器、カードゲームやビンゴ、iPadなどの遊びを取り入れ、小さな子どもたちも毎回楽しく通うことができます。遊びだけではなく鉛筆を持ってアルファベットの読み書きのほか、発音の基礎となるフォニックスを習います。
                              </p>
                              <p>
                                フォニックスとは、例えば
                                aをア、bをブ、cをクと発音するなど文字と発音のルールが習得できる学習法です。ネイティブ講師の正しい発音を繰り返し聞くことで自然と頭にインプットされて、リスニング力、スピーキング力の向上につながっていきます。
                                年に１０回実施する単語とフォニックスのミニテストやスピーキング発表会にチャレンジする機会を設けています。
                              </p>
                              <p>
                                年に３回イベントを開催します。（イースターまたは１分間チャレンジゲーム大会、ハロウィン、クリスマス）
                                <br />
                                １クラス定員６名
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 class-full-content font-18">
                          <h4 className="font-22 cff-ttl">
                            スピーキング発表会
                          </h4>
                          <p>
                            アメリカンイングリッシュハウスでは年１０回、生徒同士の英会話をクラスメイトやママたちの前で発表する小さな会を設けています。普段スクール以外で英語を話す機会が少ない中で、話せる楽しさや嬉しさを持ち続けてほしいとの思いから始めました。
                          </p>
                        </div>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
              <div className="class-services-flex wrap">
                <div className="container">
                  <div className="row">
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-group" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>１クラス定員６名の少人数制</p>
                      </div>
                    </div>
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-playing" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>遊びながら身につくレッスン</p>
                      </div>
                    </div>
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-speak" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>正しい発音の早期インプット</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Elementary class --> */}

            <div className="class-wrap green" id="elementry-class">
              <div className="class-wrap-inner wrap">
                <div className="container">
                  <div className="row">
                    <div className="classes-flex-handler">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="classes-flex-wrapper">
                          <div className="staff-cols img-cols">
                            <div className="vector-title-wrapper show-from-ipad">
                              <h2 className="font-30 class-title">
                                Elementary Class
                              </h2>
                              <h3 className="font-30 class-subtitle">
                                小学生クラス
                              </h3>
                              <div className="vector vector-elementry" />
                            </div>

                            <div className="img-wrap">
                              <Img
                                fluid={data['classesElementaryImage'].childImageSharp.fluid}
                                className="class-img" alt="小学生クラス"
                              />
                            </div>

                            <ul className="img-desc-wrap">
                              <li className="img-desc-item">
                                <i className="icon icon-x3 icon-clock" />
                                <span className="desc font-20">50分</span>
                              </li>
                              <li className="img-desc-item flex-grow-1">
                                <i className="icon icon-x3 icon-teach" />
                                <span className="desc font-20">
                                  振替レッスンあり！
                                </span>
                              </li>
                              <li className="img-desc-item">
                                <i className="icon icon-x3 icon-yen" />
                                <span className="desc font-20">¥7,700 / 月謝</span>
                              </li>
                            </ul>
                          </div>

                          <div className="staff-cols content-cols">
                            <div className="vector-title-wrapper hide-from-ipad">
                              <h2 className="font-30 class-title">
                                Elementary Class
                              </h2>
                              <h3 className="font-30 class-subtitle">
                                小学生クラス
                              </h3>
                              <div className="vector vector-elementry" />
                            </div>
                            <div className="content-wrapper">
                              <p>
                                小学生クラスは大きく３段階のレベルに分けられます。１年生から始めた場合P１→P２→P３と２年ずつレベルが上がり、６年間ですべてのカリキュラムを終了します。２年生以降の入会ももちろん可能です。その際は今までの英語学習経験やレベルを考慮し、ご相談しながらクラスを決定します。英語４技能（話す、聞く、読む、書く）をバランス良く習得するカリキュラムで総合力の向上を目指します。
                                <br />{" "}
                                小学校での英語教科化にともない１年生から文法を取り入れ、小学生でもより理解が高まるようなレッスンを行っています。フラッシュカードや英語ゲーム、iPadなども取り入れながら楽しく効果的にメリハリのある指導を心がけています。英語の発音の基本であるフォニックスはネイティブ講師の正しい発音でしっかり身につきます。
                              </p>
                              <p>
                                使用する教材は２つあります。一つはテーマ別のプリント教材、もう一つは文法テキストです。プリント教材は４回のレッスンで一つのテーマを終了する完結型で子どもたちが飽きない工夫が施されています。文法テキストは小学生向けに作られたもので難しい文法用語はほとんど出てきません。繰り返し練習できるので無理なく取り組めます。
                              </p>
                              <p>
                                {" "}
                                年に１０回実施する単語、フォニックス、Q&Aのミニテストやスピーキング発表会にチャレンジする機会を設けています。
                                年に３回イベントを開催します。（イースターまたは１分間チャレンジゲーム大会、ハロウィン、クリスマス）
                                <br />
                                １クラス定員８名{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 class-full-content font-18">
                          <h4 className="font-22 cff-ttl">
                            スピーキング発表会
                          </h4>
                          <p>
                            アメリカンイングリッシュハウスでは年１０回、生徒同士の英会話をクラスメイトやママたちの前で発表する小さな会を設けています。普段スクール以外で英語を話す機会が少ない中で、話せる楽しさや嬉しさを持ち続けてほしいとの思いから始めました。
                          </p>
                        </div>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
              <div className="class-services-flex wrap">
                <div className="container">
                  <div className="row">
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-group" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>１クラス定員８名の少人数制</p>
                      </div>
                    </div>
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-playing" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>英語の総合力が上がるカリキュラム</p>
                      </div>
                    </div>
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-speak" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>小学生向けのわかりやすい英文法レッスン</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Junior & High School Class --> */}
            <div className="class-wrap blue" id="junior-class">
              <div className="class-wrap-inner wrap">
                <div className="container">
                  <div className="row">
                    <div className="classes-flex-handler">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="classes-flex-wrapper">
                          <div className="staff-cols img-cols">
                            <div className="vector-title-wrapper vector-small show-from-ipad">
                              <h2 className="font-30 class-title">
                                JHS & HS Class
                              </h2>
                              <h3 className="font-30 class-subtitle">
                                中高生クラス
                              </h3>
                              <div className="vector vector-school" />
                            </div>

                            <div className="img-wrap">
                              <Img
                                fluid={data['classesJhsHsImage'].childImageSharp.fluid}
                                className="class-img" alt="中高生クラス"
                              />

                            </div>

                            <ul className="img-desc-wrap">
                              <li className="img-desc-item">
                                <i className="icon icon-x3 icon-clock" />
                                <span className="desc font-20">50分</span>
                              </li>
                              <li className="img-desc-item">
                                <i className="icon icon-x3 icon-yen" />
                                <span className="desc font-20">¥7,700 / 月謝</span>
                              </li>
                            </ul>
                          </div>

                          <div className="staff-cols content-cols">
                            <div className="vector-title-wrapper vector-small hide-from-ipad">
                              <h2 className="font-30 class-title">
                                JHS & HS Class
                              </h2>
                              <h3 className="font-30 class-subtitle">
                                中高生クラス
                              </h3>
                              <div className="vector vector-school" />
                            </div>
                            <div className="content-wrapper">
                              <p>
                                中高生クラスは中学生と高校生の２つのクラスに分けられます。英会話ベースのテキストを使用し日常で使える英単語や英語表現、英文法、海外の文化や知識なども幅広く学べます。最大定員６名までの少人数クラスなので話す機会が多いのもメリットです。
                              </p>

                              <p>
                                今後、学校での英語の授業はすべて英語で行う方向性が示されている中、ネイティブ講師とのコミュニケーションや英語で発言する機会を増やすことが必要です。スピーキングに関しては「習うより慣れろ」です。ネイティブ講師の真似をし、それを自分なりにアレンジして英語で意見が言えるようにサポートしていきます。
                                <br />
                                １クラス定員６名
                              </p>
                            </div>
                          </div>
                        </div>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
              <div className="class-services-flex wrap">
                <div className="container">
                  <div className="row">
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-group" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>１クラス定員６名の少人数制</p>
                      </div>
                    </div>
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-playing" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>
                          英会話力、コミュニケーション力が上がるカリキュラム
                        </p>
                      </div>
                    </div>
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-speak" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>文法のポイントを押さえた学習内容</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Adult Class --> */}
            <div className="class-wrap red" id="adult-class">
              <div className="class-wrap-inner wrap">
                <div className="container">
                  <div className="row">
                    <div className="classes-flex-handler">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="classes-flex-wrapper">
                          <div className="staff-cols img-cols">
                            <div className="vector-title-wrapper show-from-ipad">
                              <h2 className="font-30 class-title">
                                Adult Class
                              </h2>
                              <h3 className="font-30 class-subtitle">
                                大人クラス
                              </h3>
                              <div className="vector vector-adult" />
                            </div>

                            <div className="img-wrap">
                              <Img
                                fluid={data['classesAdultImage'].childImageSharp.fluid}
                                className="class-img" alt="大人クラス"
                              />
                            </div>
                            <ul className="img-desc-wrap">
                              <li className="img-desc-item">
                                <i className="icon icon-x3 icon-clock" />
                                <span className="desc font-20">50分</span>
                              </li>
                              <li className="img-desc-item">
                                <i className="icon icon-x3 icon-yen" />
                                <span className="desc font-20">¥7,700 / 月謝</span>
                              </li>
                            </ul>
                          </div>

                          <div className="staff-cols content-cols">
                            <div className="vector-title-wrapper hide-from-ipad">
                              <h2 className="font-30 class-title">
                                Adult Class
                              </h2>
                              <h3 className="font-30 class-subtitle">
                                大人クラス
                              </h3>
                              <div className="vector vector-adult" />
                            </div>
                            <div className="content-wrapper">
                              <p>
                                大人クラスはグループレッスンとプライベートレッスンから選択できます。グループレッスンは１クラスの定員が４名ですので、ゆったりとレッスンに参加していただけます。英会話ベースのテキストはトピック別で飽きのこない内容です。すぐに使える単語や言い回しをどんどん増やしてさらに具体的な会話を目指しましょう。
                              </p>
                              <p>
                                プライベートレッスンでは例えば海外旅行で使える英会話やビジネスシーンで役立つ英会話、TOEIC対策などご希望に応じてレッスンを組み立てることが可能です。。
                                趣味やニュース、興味のあることなど日常の身近な話題をお互いに話すウォーミングアップから英語シャワーの始まりです。ビギナーの方も恐れることなくどんどん講師に話しかけましょう。トライすることが上達の近道です。ネイティブ講師の正しい発音を聞き、真似をして、使える英語を増やしていきましょう。
                              </p>
                              <p>１クラス定員４名（グループレッスン）</p>
                            </div>
                          </div>
                        </div>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>

              <div className="class-services-flex wrap">
                <div className="container">
                  <div className="row">
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-group" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>１クラス定員4名の少人数制</p>
                      </div>
                    </div>
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-playing" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>英会話力、コミュニケーション力が上がるカリキュラム</p>
                      </div>
                    </div>
                    <div className="class-services-grid">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="class-services-icon">
                          <i className="icon icon-bigger icon-speak" />
                        </div>
                      </ScrollAnimation>
                      <div className="class-services-text font-20">
                        <p>目的、目標に合わせたレッスン内容</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </section>

        {/* <!-- Tuition Fees Lesson --> */}

        <section id="fees" className="fees-section inner-padding">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={1}
                  animateOnce={true}
                >
                  <h3 className="title title-alt">
                    <span>授業料</span>
                  </h3>
                </ScrollAnimation>

                <div className="fees-row row">
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    duration={1}
                    animateOnce={true}
                    className="fees-cols yellow"
                  >
                    <div className="fees-inner">
                      <div className="colored-title-wrap">
                        <h3 className="fees-title">園児クラス</h3>
                      </div>
                      <div className="desc-wrapper">
                        <table className="desc-table heading6-text">
                          <tbody>
                            <tr>
                              <td>レッスン時間</td>
                              <td>50分</td>
                            </tr>
                            <tr>
                              <td>クラス定員</td>
                              <td>6</td>
                            </tr>
                            <tr>
                              <td>月謝</td>
                              <td>¥7,700</td>
                            </tr>
                            <tr>
                              <td>教材費（年間）</td>
                              <td>¥4,950</td>
                            </tr>
                            <tr>
                              <td>入会金</td>
                              <td>¥4,400</td>
                            </tr>
                            <tr>
                              <td>イベント費</td>
                              <td>¥1,650</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    duration={1}
                    animateOnce={true}
                    className="fees-cols green"
                  >
                    <div className="fees-inner">
                      <div className="colored-title-wrap">
                        <h3 className="fees-title">小学生クラス</h3>
                      </div>
                      <div className="desc-wrapper">
                        <table className="desc-table heading6-text">
                          <tbody>
                            <tr>
                              <td>レッスン時間</td>
                              <td>50分</td>
                            </tr>
                            <tr>
                              <td>クラス定員</td>
                              <td>8</td>
                            </tr>
                            <tr>
                              <td>月謝</td>
                              <td>¥7,700</td>
                            </tr>
                            <tr>
                              <td>教材費（年間）</td>
                              <td>¥8,250</td>
                            </tr>
                            <tr>
                              <td>入会金</td>
                              <td>¥4,400</td>
                            </tr>
                            <tr>
                              <td>イベント費</td>
                              <td>¥1,650</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    duration={1}
                    animateOnce={true}
                    className="fees-cols blue"
                  >
                    <div className="fees-inner">
                      <div className="colored-title-wrap">
                        <h3 className="fees-title">中高生クラス</h3>
                      </div>
                      <div className="desc-wrapper">
                        <table className="desc-table heading6-text">
                          <tbody>
                            <tr>
                              <td>レッスン時間</td>
                              <td>50分</td>
                            </tr>
                            <tr>
                              <td>クラス定員</td>
                              <td>6</td>
                            </tr>
                            <tr>
                              <td>月謝</td>
                              <td>¥7,700</td>
                            </tr>
                            <tr>
                              <td>教材費（年間）</td>
                              <td>¥5,500</td>
                            </tr>
                            <tr>
                              <td>入会金</td>
                              <td>¥4,400</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    duration={1}
                    animateOnce={true}
                    className="fees-cols red"
                  >
                    <div className="fees-inner">
                      <div className="colored-title-wrap">
                        <h3 className="fees-title">大人クラス</h3>
                      </div>
                      <div className="desc-wrapper">
                        <table className="desc-table heading6-text">
                          <tbody>
                            <tr>
                              <td>レッスン時間</td>
                              <td>50分</td>
                            </tr>
                            <tr>
                              <td>クラス定員</td>
                              <td>4</td>
                            </tr>
                            <tr>
                              <td>月謝</td>
                              <td>¥7,700</td>
                            </tr>
                            <tr>
                              <td>教材費（年間）</td>
                              <td>¥5,500</td>
                            </tr>
                            <tr>
                              <td>入会金</td>
                              <td>¥4,400</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--  Lesson --> */}
        <section className="special-info-section inner-padding">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="title title-alt">
                  <span>その他お知らせ</span>
                </h3>
                <div className="special-info-row font-18">
                  <div className="special-info-cols col-xs-6 col-sm-3">
                    {" "}
                    家族割引（２人目）{" "}
                  </div>

                  <div className="special-info-cols col-xs-6 col-sm-3">
                    月謝 ¥1,000オフ
                  </div>
                  <div className="special-info-cols col-xs-6 col-sm-3">
                    入会金 ¥0
                  </div>
                </div>
                <div className="special-info-row font-18">

                  <div className="special-info-cols col-xs-6 col-sm-3">
                    家族割引（３人目）
                  </div>
                  <div className="special-info-cols col-xs-6 col-sm-3">
                    月謝 ¥2,000オフ
                  </div>
                  <div className="special-info-cols col-xs-6 col-sm-3">
                    入会金 ¥0
                  </div>
                </div>
                <div className="special-info-row font-18">
                  <div className="special-info-cols col-xs-6 col-sm-3">
                    リーディング・プログラム
                  </div>
                  <div className="special-info-cols col-xs-6 col-sm-3">
                    月謝 ¥1,100
                  </div>
                  <div className="special-info-cols col-xs-6 col-sm-3">
                    希望者のみ
                  </div>
                </div>
                <div className="special-info-row font-18">
                  <div className="special-info-cols col-xs-6 col-sm-3">
                    振替レッスン
                  </div>

                  <div className="special-info-cols col-xs-6 col-sm-3">
                    園児＆小学生クラスのみ
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Free Trial Lesson --> */}
        <FreeTrialLesson title="無料体験レッスン" button="お申し込みはこちら" />
      </Layout>
    </>
  )
}
export default ClassesPage
